import Panzoom from "@panzoom/panzoom";
import pdfjsLib from "pdfjs-dist/build/pdf";
import { PDFSinglePageViewer } from "pdfjs-dist/web/pdf_viewer";
import { EventBus }  from "pdfjs-dist/web/pdf_viewer";
import "pdfjs-dist/web/pdf_viewer.css";

const eventBus = new EventBus()

export default {
  name: 'PdfVisualizer',
  props: {
    url: String,
  },

  data () {
    return {
      encodedUrl: null,
      scale: 1,
    }
  },

  created () {
    this.encodedUrl = encodeURIComponent(this.url);
  },
  mounted () {
    console.log('pdf', this.url);
    this.fetchPDF();
  },
 

  methods: {
    async fetchPDF() {
      // pdfjsLib.GlobalWorkerOptions.workerSrc = './node_modules/pdfjs-dist/build/pdf.worker.js';
      
      const container = document.getElementById("page-container");
      //const viewer = document.getElementById("viewer");
      const pdfViewer = new PDFSinglePageViewer({
        container: container, eventBus
      });
 
      const token = localStorage.getItem('angelus-token') ?? '';
      const pdf = await pdfjsLib.getDocument({
        url: this.url,
        httpHeaders: { Authorization: `Bearer ${token}` },
        withCredentials: true,
      });
      // let pdf = await pdfjsLib.getDocument(this.url);
      pdfViewer.setDocument(pdf);
      
      this.panzoom = Panzoom(container, {
        minScale: 0.1,
      });      
    },
    zoom(offset) {
      this.panzoom.zoom(this.scale + offset);
      this.scale = this.panzoom.getScale();
    },
    resetZoom() {
      this.panzoom.reset();
      this.scale = this.panzoom.getScale();
    },
    setZoom(value) {      
      this.panzoom.zoom(value);
      console.log(this.panzoom.getPan())
      this.scale = this.panzoom.getScale();
    },
  },
}