import Panzoom from "@panzoom/panzoom";
import { base_doc_uri, get_document, get_polygons_in_document } from "../../../api/ontologias";

export default {
  name: 'ImgVisualizer',
  props: {
    url: String,
    docId: String,
  },

  data () {
    return {
      imageUrl: null,
      scale: 1,
      canvas: null,
      ctx: null,
      isDown: false, // this flag is true when the user is dragging the mouse
      // these vars will hold the starting mouse position
      offsetX: undefined,
      offsetY: undefined,
      startX: undefined,
      startY: undefined,
      scaleX: undefined,
      scaleY: undefined,
      annotationMode: true,
      currentPolygon: {
        upper_left_x: 0,
        upper_left_y: 0,
        lower_right_x: 0,
        lower_right_y: 0,
        hex_color: '#FF0000'
      }
    }
  },

  async created () {
    
  },

  async mounted() {
    console.log('+++', this.docId);
    const image = await get_document(this.url);
    this.imageUrl = await this.getBase64(image);
  
    /*this.initCanvas();
    await this.getPolygons();
    const imageObj = new Image();
    imageObj.onload = () => {
      const ratio = imageObj.naturalWidth / imageObj.naturalHeight;

      this.canvas.height = this.canvas.width / ratio;
      // this.ctx.drawImage(imageObj, 0, 0, this.canvas.width, this.canvas.width / ratio);

      // calculate where the canvas is on the window
      // (used to help calculate mouseX/mouseY)
      const canvasOffset = this.canvas.getBoundingClientRect();
      console.log('Offset', canvasOffset);
      this.offsetX = canvasOffset.left;
      this.offsetY = canvasOffset.top;
      this.scaleX = this.canvas.width / canvasOffset.width,    // relationship bitmap vs. element for x
      this.scaleY = this.canvas.height / canvasOffset.height;  // relationship bitmap vs. element for y
      // this.ctx.strokeRect(20, 10, 50, 50);
    };
    imageObj.src = this.imageUrl;
    */
    this.panzoom = Panzoom(document.getElementById('img-container'), {
      minScale: 1,
    });
  },

  methods: {
    get_doc_uri () { 
      return `<${base_doc_uri}${this.docId}>`
    },
    initCanvas() {
      this.canvas = document.getElementById('canvas');
      this.ctx = this.canvas.getContext('2d');
      this.ctx.strokeStyle = '#FF0000';
      this.ctx.lineWidth = 2;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    zoom(offset) {
      this.panzoom.zoom(this.scale + offset);
      this.scale = this.panzoom.getScale();
    },
    resetZoom() {
      this.panzoom.reset();
      this.scale = this.panzoom.getScale();
    },
    setZoom(value) {
      this.panzoom.zoom(value);
      this.scale = this.panzoom.getScale();
    },
    handleMouseDown(e) {
      console.log('handleMouseDown');
      console.log(e)
      e.preventDefault();
      e.stopPropagation();
  
      // save the starting x/y of the rectangle
      // this.startX = parseInt(e.clientX - this.offsetX);
      // this.startY = parseInt(e.clientY - this.offsetY);
      this.startX = (e.clientX - this.offsetX) * this.scaleX;
      this.startY = (e.clientY - this.offsetY) * this.scaleY;
  
      // set a flag indicating the drag has begun
      this.isDown = true;
    },
    handleMouseUp(e) {
      console.log('handleMouseUp')
      console.log(e)
      e.preventDefault();
      e.stopPropagation();
  
      // the drag is over, clear the dragging flag
      this.isDown = false;
    },
    handleMouseOut(e) {
      console.log('handleMouseOut')
      console.log(e)
      e.preventDefault();
      e.stopPropagation();
  
      // the drag is over, clear the dragging flag
      this.isDown = false; 
    },
    handleMouseMove(e) {
      e.preventDefault();
      e.stopPropagation();
  
      // if we're not dragging, just return
      if (!this.isDown) {
          return;
      }
  
      // get the current mouse position
      // const mouseX = parseInt(e.clientX - this.offsetX);
      // const mouseY = parseInt(e.clientY - this.offsetY);
      const mouseX = (e.clientX - this.offsetX) * this.scaleX;
      const mouseY = (e.clientY - this.offsetY) * this.scaleY;
  
      // Put your mousemove stuff here
  
      // clear the canvas
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  
      // calculate the rectangle width/height based
      // on starting vs current mouse position
      const width = mouseX - this.startX;
      const height = mouseY - this.startY;
      
      // draw a new rect from the start position 
      // to the current mouse position
      this.ctx.strokeRect(this.startX, this.startY, width, height);

      if (width > 1 && height > 1) {
        this.currentPolygon = {
          upper_left_x: this.startX,
          upper_left_y: this.startY,
          lower_right_x: this.mouseX,
          lower_right_y: this.mouseY,
          hex_color: '#FF0000'
        };
      } 
    },
    async getPolygons() {
      const existingPolygons = await get_polygons_in_document(this.get_doc_uri());
      console.log('++++existing', existingPolygons);
    },
    async savePolygon() {

    },
  },
}